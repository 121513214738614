import React, { useState } from 'react';
import '../App.scss';
import Posts from './Posts';
import Home from './Home';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import Sandbox from './Sandbox';
import Navbar from './Navbar';
import PostIsolate from './PostIsolate';
import Pomororo from './Pomodoro/Pomodoro';

const App = () => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [sidebarEnable, setSidebarEnable] = useState(false);
  const [sidebarItems, setSidebarItems] = useState(null);
  const [focusSidebarItem, setFocusSidebarItem] = useState('');

  return (
    <>
      <Router>
        <Navbar
          {...{
            sidebarToggle,
            setSidebarToggle,
            sidebarItems,
            focusSidebarItem,
            sidebarEnable,
          }}
        />

        <Route
          render={({ location }) => (
            <Switch>
              <Route
                exact
                path="/"
                render={() => (
                  <Home {...{ setSidebarEnable, setSidebarToggle }} />
                )}
              />
              <Route
                exact
                path="/blog"
                render={() => (
                  <Posts
                    {...{
                      setSidebarItems,
                      sidebarToggle,
                      setSidebarToggle,
                      setFocusSidebarItem,
                      setSidebarEnable,
                    }}
                  />
                )}
              />
              <Route
                exact
                path="/blog:pageHash"
                render={() => <PostIsolate {...{ setSidebarEnable }} />}
              />
              <Route exact path="/dev" component={Sandbox} />
              <Route exact path="/pomodoro" component={Pomororo} />

              <Redirect to="/" />
            </Switch>
          )}
        />
        {/* <Home /> */}
        {/* </div> */}
      </Router>
    </>
  );
};

export default App;
