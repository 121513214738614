import time from "../../util/TimerFp";

//initializeTimer :: pomodoroState -> timerState
export const initializeTimer = (timerMode) => (state) =>
  time.initilize(state.timerTimes[timerMode]);

const isActive = (state) => (state?.timer.active ? true : false);

export const action = (type) => (state) => (payload) => {
  const { timer } = state;
  switch (type) {
    case "TASK-CHANGE":
      return { ...state, taskName: payload?.target?.value };
    case "INITIALIZE":
      return !isActive(state)
        ? {
            ...state,
            timerMode: payload,
            timer: initializeTimer(payload)(state),
          }
        : { ...state };
    case "RESET":
      const timerMode = state.timerMode;
      return {
        ...state,
        taskName: "",
        timerMode: timerMode,
        timer: initializeTimer(timerMode)(state),
      };
    case "START":
      const start = { ...state, timer: time.start(payload)(timer) };
      return start;
    case "PAUSE":
      const pause = { ...state, timer: time.stop(timer) };
      return pause;
    case "UPDATE-TIMER":
      return { ...state, timer: time.updateTimer(payload)(timer) };
    case "MUTE-TOGGLE":
      return { ...state, muted: !state.muted };
    default:
      return { ...state };
  }
};
