import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useWindowSize } from 'react-use';
import { post as postapi } from '../api/notion';
import 'react-notion/src/styles.css';
import 'prismjs/themes/prism-tomorrow.css'; // only needed for code highlighting
import { NotionRenderer } from 'react-notion';
import ContentLoader from 'react-content-loader';
import useVisible from '../hooks/useVisible';
import { useParams, useHistory } from 'react-router-dom';

const Post = ({ post, setFocusSidebarItem }) => {
  const [blockMap, setBlockMap] = useState(false);
  let { width } = useWindowSize();
  const elementRef = useRef(null);
  const { isIntersecting } = useVisible(elementRef);
  const [enablePost, setEnablePost] = useState(false);
  const [isIsolated, setIsIsolated] = useState(false);
  const param = useParams();
  const history = useHistory();

  useEffect(() => {
    if (param.pageHash) {
      setIsIsolated(true);
    }
  }, []);

  useEffect(() => {
    setEnablePost(isIntersecting);
  }, [isIntersecting]);

  useEffect(() => {
    if (enablePost && !isIsolated) {
      setFocusSidebarItem(post.id);
    }

    if (!blockMap && enablePost) {
      fetchSetBlockMap();
    }
  }, [enablePost]);

  const renderLoader = () => {
    //row grid length for --blog
    let LoaderWidth = width < 1000 ? width - 60 : 1000;
    return enablePost ? (
      <ContentLoader
        viewBox={`0 0 ${LoaderWidth} 700`}
        height={700}
        width={LoaderWidth}
        speed={1}
        animate={true}
      >
        <rect x="0" y="20" rx="0" ry="0" width={LoaderWidth / 2} height="60" />
        <rect x="0" y="100" rx="4" ry="4" width={LoaderWidth / 4} height="20" />
        <rect
          x="0"
          y="130"
          rx="4"
          ry="4"
          width={LoaderWidth / 1.5}
          height="20"
        />
        <rect x="0" y="160" rx="4" ry="4" width={LoaderWidth / 3} height="20" />
        <rect x="0" y="210" rx="4" ry="4" width={LoaderWidth} height="20" />
        <rect
          x="0"
          y="240"
          rx="4"
          ry="4"
          width={LoaderWidth / 1.75}
          height="20"
        />
        <rect x="0" y="300" rx="4" ry="4" width={LoaderWidth} height="200" />
        <rect x="0" y="520" rx="4" ry="4" width={LoaderWidth} height="20" />
      </ContentLoader>
    ) : (
      <div className="ghost-gap"></div>
    );
  };

  const fetchSetBlockMap = async () => {
    const response = await postapi.get(post.id);
    setBlockMap(response.data);
  };

  const navigateToIsolatedPost = () => {
    history.push(`/blog${post.id}`);
  };

  return (
    <div className="post" id={post.id}>
      <h1
        ref={elementRef}
        onClick={navigateToIsolatedPost}
        style={{ cursor: 'pointer' }}
        className="heading-primary"
      >
        {post.Name}
      </h1>
      <h3 className="heading-secondary posts__date  u-margin-bottom-small">
        {post.date ? post.date : ''}
      </h3>

      {blockMap ? (
        <NotionRenderer block_width={width} blockMap={blockMap} />
      ) : (
        renderLoader()
      )}
    </div>
  );
};

export default Post;
