import React from 'react';
import * as Oct from '@primer/octicons-react';

function ThemeSwitcher({ darkMode }) {
  const renderThemeIcon = () => {
    return darkMode.value ? (
      <span className="sun-icon">
        <Oct.SunIcon size={25} />
      </span>
    ) : (
      <span className="moon-icon">
        <Oct.MoonIcon size={25} />
      </span>
    );
  };
  // console.log(darkMode)
  return (
    <div
      className="ThemeSwitcher"
      onClick={() => {
        darkMode.toggle();
      }}
    >
      {/* <Oct.DotFillIcon size={25} /> */}
      {renderThemeIcon()}
    </div>
  );
}

export default ThemeSwitcher;
