import React, { useState } from 'react';

const InlineTextEdit = ({ taskName, onChange }) => {
  const [showEditor, setShowEditor] = useState(true);
  const renderLabel = () => {
    return (
      <h2
        className="pomodoro__label pomodoro__label--large"
        onClick={() => {
          setShowEditor(true);
        }}
      >
        {taskName}
      </h2>
    );
  };
  const renderTextArea = () => {
    return (
      <textarea
        class="pomodoro__textarea"
        placeholder="Enter Task Here"
        rows="1"
        columns="4"
        onChange={onChange}
        onKeyDown={onEnterPress}
        value={taskName}
      ></textarea>
    );
  };

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      setShowEditor(false);
    }
  };

  return <>{showEditor ? renderTextArea() : renderLabel()}</>;
};

export default InlineTextEdit;
