import React from 'react';
import useDarkMode from 'use-dark-mode';
import ThemeSwitcher from './ThemeSwitcher';
import Sidebar from './Sidebar';
import { HomeIcon, RepoIcon, ClockIcon } from '@primer/octicons-react';
import { Link, useLocation } from 'react-router-dom';

const Navbar = ({
  sidebarToggle,
  setSidebarToggle,
  sidebarItems,
  focusSidebarItem,
  sidebarEnable,
}) => {
  const darkMode = useDarkMode(false);
  const location = useLocation();
  console.log(location.pathname);
  const renderNavbarRight = () => {
    return sidebarEnable ? (
      <>
        <Sidebar
          {...{
            sidebarToggle,
            setSidebarToggle,
            sidebarItems,
            focusSidebarItem,
          }}
        />
      </>
    ) : (
      <Link className="navbar__blog-nav " to="/blog">
        <RepoIcon size={24} />
      </Link>
    );
  };
  return (
    <>
      <div className="navbar">
        <ThemeSwitcher {...{ darkMode }} />
        {renderNavbarRight()}
        {location.pathname === '/' ? (
          <Link className="navbar__pomo" to="/pomodoro">
            <ClockIcon size={24} />
          </Link>
        ) : (
          ''
        )}
        {location.pathname !== '/' ? (
          <Link className="navbar__home" to="/">
            <HomeIcon size={24} />
          </Link>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default React.memo(Navbar);
