import React, { useState, useEffect } from 'react';

function TestComponent(props) {}

const Sandbox = () => {
  const [clock, setClock] = useState(null);

  useEffect(() => {
    setClock(300);
  }, []);

  useEffect(() => {
    if (clock > 0 && clock) {
      setTimeout(() => {
        setClock(clock - 1);
        console.log('in use effect ' + clock);
      }, 1000);
    }
  }, [clock]);

  return <div>{clock}</div>;
};

export default Sandbox;
