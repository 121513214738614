import React, { useState, useEffect } from 'react';
import { post, table } from '../api/notion';
import Post from './Post';
// import Spring,{useSpring, animated,useTransition,config} from 'react-spring';

const Posts = ({
  setSidebarItems,
  setSidebarToggle,
  sidebarToggle,
  setFocusSidebarItem,
  setSidebarEnable,
}) => {
  const tableId = 'f8a5efca91c54567838731bb79a1b0fc';
  const [blockMap, setBlockMap] = useState(null);
  const [posts, setPosts] = useState(null);

  const fluffShiftLeft = {
    transform: 'translateX(-15rem)',
    transition: 'transform .3s cubic-bezier(.68,.25,.16,1.27)',
  };

  const fluffShiftRight = {
    transform: 'translateX(0rem)',
    transition: 'transform .3s cubic-bezier(.68,.25,.16,1.27)',
  };

  useEffect(() => {
    setSidebarEnable(true);
    setSidebarToggle(false);
    fetchSetTableIds();
    fetchSetBlockMap();
  }, []);

  useEffect(() => {
    // setSidebarToggle(false)
    if (posts) {
      setSidebarItems(
        posts.map((post) => {
          return {
            id: [post.id],
            name: [post.Name],
            url: ['/' + post.id],
          };
        })
      );
    }
  }, [posts]);

  const fetchSetTableIds = async () => {
    const response = await table.get(tableId);
    setPosts(response.data);
  };

  const fetchSetBlockMap = async () => {
    const response = await post.get('e6895eaa062843c78c1591e3d4a0ff9e');
    setBlockMap(response.data);
  };

  const renderPosts = () => {
    return posts.map((post) => {
      return (
        <>
          <Post
            post={post}
            key={post.id}
            setFocusSidebarItem={setFocusSidebarItem}
          />
        </>
      );
    });
  };

  // const buttonTransition=useTransition(sidebarToggle, null, {
  //   from: {
  //     transform: 'translate3d(200px, 0px, 0px)',
  //   },
  //   enter: {
  //     transform: 'translate3d(0px, 0px, 0px)',
  //   },
  //   leave: {
  //     transform: 'translate3d(0px, 0px, 0px)',
  //   },
  //   config: config.gentle,
  // });

  return (
    //     buttonTransition.map(({item,key,props})=>
    //       <animated.div className="strechy" key={key} style={props}>
    //          <section className="posts">
    //       <div className='row row--blog'>

    //           {posts?renderPosts():''}

    //       </div>
    //     </section>
    //       </animated.div>
    //    )
    // )

    <div
      className="strechy"
      style={sidebarToggle ? fluffShiftLeft : fluffShiftRight}
    >
      <section className="posts">
        <div className="row row--blog">{posts ? renderPosts() : ''}</div>
      </section>
    </div>
  );
};

export default Posts;
