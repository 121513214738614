import React, { useState, useEffect, useRef } from 'react';
import { useWindowSize } from 'react-use';
import { post as postapi } from '../api/notion';
import { table as tableApi } from '../api/notion';
import 'react-notion/src/styles.css';
import 'prismjs/themes/prism-tomorrow.css'; // only needed for code highlighting
import { NotionRenderer } from 'react-notion';
import ContentLoader from 'react-content-loader';
import useVisible from '../hooks/useVisible';
import { useParams } from 'react-router-dom';

const Post = ({ setSidebarEnable }) => {
  const [blockMap, setBlockMap] = useState(false);
  let { width } = useWindowSize();
  const elementRef = useRef(null);
  const { isIntersecting } = useVisible(elementRef);
  const [enablePost, setEnablePost] = useState(false);
  const [table, setTable] = useState(null);
  const param = useParams();
  const [post, setPost] = useState();
  const tableId = 'f8a5efca91c54567838731bb79a1b0fc';

  useEffect(() => {
    fetchSetTable();
    setSidebarEnable(false);
  }, []);

  useEffect(() => {
    if (table) {
      table.forEach((item) => {
        if (item.id === param.pageHash) {
          setPost({
            Name: [item.Name],
            date: [item.date],
          });
        }
      });
    }
  }, [table]);

  useEffect(() => {
    setEnablePost(isIntersecting);
  }, [isIntersecting]);

  const fetchSetTable = async () => {
    const response = await tableApi.get(tableId);
    setTable(response.data);
  };

  useEffect(() => {
    if (!blockMap && enablePost) {
      fetchSetBlockMap();
    }
  }, [enablePost]);

  const renderLoader = () => {
    //row grid length for --blog
    let LoaderWidth = width < 1000 ? width - 60 : 1000;
    return enablePost ? (
      <ContentLoader
        viewBox={`0 0 ${LoaderWidth} 700`}
        height={700}
        width={LoaderWidth}
        speed={1}
        animate={true}
      >
        <rect x="0" y="20" rx="0" ry="0" width={LoaderWidth / 2} height="60" />
        <rect x="0" y="100" rx="4" ry="4" width={LoaderWidth / 4} height="20" />
        <rect
          x="0"
          y="130"
          rx="4"
          ry="4"
          width={LoaderWidth / 1.5}
          height="20"
        />
        <rect x="0" y="160" rx="4" ry="4" width={LoaderWidth / 3} height="20" />
        <rect x="0" y="210" rx="4" ry="4" width={LoaderWidth} height="20" />
        <rect
          x="0"
          y="240"
          rx="4"
          ry="4"
          width={LoaderWidth / 1.75}
          height="20"
        />
        <rect x="0" y="300" rx="4" ry="4" width={LoaderWidth} height="200" />
        <rect x="0" y="520" rx="4" ry="4" width={LoaderWidth} height="20" />
      </ContentLoader>
    ) : (
      <div className="ghost-gap"></div>
    );
  };

  const fetchSetBlockMap = async () => {
    const response = await postapi.get(param.pageHash);
    setBlockMap(response.data);
  };

  return (
    <section ref={elementRef} className="posts">
      <div className="row row--blog">
        <div className="post" id={param.pageHash}>
          {post ? (
            <>
              <h1 className="heading-primary">{post.Name}</h1>
              {post.date ? (
                <h3 className="heading-secondary posts__date  u-margin-bottom-small">
                  {post.date ? post.date : ''}
                </h3>
              ) : (
                ''
              )}
            </>
          ) : (
            ''
          )}

          {blockMap ? (
            <NotionRenderer block_width={width} blockMap={blockMap} />
          ) : (
            renderLoader()
          )}
        </div>
      </div>
    </section>
  );
};

export default Post;
