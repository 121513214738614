import React from 'react';
import * as Oct from '@primer/octicons-react';

const Home = ({ setSidebarEnable, setSidebarToggle }) => {
  setSidebarEnable(false);
  setSidebarToggle(false);
  return (
    <>
      <section className="intro">
        <div className="row">
          <p>
            Hi! I'm Abhishek, I build amazing experiences for businesses. I work
            full stack.
          </p>
          <p>
            <span className="intro__opportunities">
              Open for opportunities <Oct.StarIcon size={20} />
            </span>
          </p>
          <div className="intro__buttons">
            <a
              className="btn btn--blue"
              href="https://gprgrq.by.files.1drv.com/y4m2KPOOykeyl86wp3eGY0SOg6UXjL224w6M-_2oW0nwKFn_TkzwbuE2hZGqg3jnZuAAS2aM5r_t0GCyz8mDFJLXeVGAqd42Oe41mE-NaxNQSdDm7Do_9EZcryEXVy1xSbKKqKMdd9hClUuvEQk7vT5R4S8QHOsbahIp_UzCVR88xEWXz_gV1M3tpMN-OU6MuhGhJEUqzTkut4fWJSSiepHPA"
            >
              Resume <Oct.DownloadIcon size={20} />
            </a>
            {/* <a className="btn btn--orange">Portfolio <Oct.FileCodeIcon size={20} /></a> */}
            <a
              className="btn btn--green"
              href="mailto:kundu_abhishek@outlook.com"
            >
              Contact Me <Oct.MailIcon size={20} />
            </a>
          </div>
        </div>
      </section>

      <section className="socials">
        <div className="row">
          <h1 className="heading-primary u-margin-bottom-small">Socials</h1>
          <div className="social social--linkedin">
            <a
              className="btn btn--link"
              href="https://www.linkedin.com/in/abhishek-kundu/"
              target="_blank"
            >
              <svg
                className="linkedin"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
              </svg>
              LinkedIn
            </a>
          </div>
          <div className="social">
            <a
              className="btn btn--link"
              href="https://www.youtube.com/channel/UC07_FbC64y9TeVk01_AL9qA/videos"
              target="_blank"
            >
              <svg
                height="27"
                viewBox="0 -120 512.00213 512"
                width="27"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m501.453125 56.09375c-5.902344-21.933594-23.195313-39.222656-45.125-45.128906-40.066406-10.964844-200.332031-10.964844-200.332031-10.964844s-160.261719 0-200.328125 10.546875c-21.507813 5.902344-39.222657 23.617187-45.125 45.546875-10.542969 40.0625-10.542969 123.148438-10.542969 123.148438s0 83.503906 10.542969 123.148437c5.90625 21.929687 23.195312 39.222656 45.128906 45.128906 40.484375 10.964844 200.328125 10.964844 200.328125 10.964844s160.261719 0 200.328125-10.546875c21.933594-5.902344 39.222656-23.195312 45.128906-45.125 10.542969-40.066406 10.542969-123.148438 10.542969-123.148438s.421875-83.507812-10.546875-123.570312zm0 0"
                  fill="#f00"
                />
                <path
                  d="m204.96875 256 133.269531-76.757812-133.269531-76.757813zm0 0"
                  fill="#fff"
                />
              </svg>
              YouTube
            </a>
          </div>
          <div className=" social social--medium">
            <a
              className="btn btn--link"
              href="https://medium.com/@abhishek.kundu"
              target="_blank"
            >
              <svg
                width="24"
                height="24"
                xmlns="http://www.w3.org/2000/svg"
                fill-rule="evenodd"
                clip-rule="evenodd"
              >
                <path d="M19 24h-14c-2.761 0-5-2.239-5-5v-14c0-2.761 2.239-5 5-5h14c2.762 0 5 2.239 5 5v14c0 2.761-2.237 4.999-5 5zm.97-5.649v-.269l-1.247-1.224c-.11-.084-.165-.222-.142-.359v-8.998c-.023-.137.032-.275.142-.359l1.277-1.224v-.269h-4.422l-3.152 7.863-3.586-7.863h-4.638v.269l1.494 1.799c.146.133.221.327.201.523v7.072c.044.255-.037.516-.216.702l-1.681 2.038v.269h4.766v-.269l-1.681-2.038c-.181-.186-.266-.445-.232-.702v-6.116l4.183 9.125h.486l3.593-9.125v7.273c0 .194 0 .232-.127.359l-1.292 1.254v.269h6.274z" />
              </svg>
              Medium
            </a>
          </div>
          <div className="social social--github">
            <a
              className="btn btn--link"
              href="https://github.com/akundu92"
              target="_blank"
            >
              <Oct.MarkGithubIcon size={24} />
              GitHub
            </a>
          </div>
          {/* <Sandbox postId='77d3dc6dec0e4e4688a03434b389e175'/> */}
        </div>
      </section>
    </>
  );
};

export default Home;
