import React, { useState } from 'react';
import * as Oct from '@primer/octicons-react';
import { Link } from 'react-router-dom';
const fluffShiftLeft = {
  transition: 'transform .3s cubic-bezier(.68,.25,.16,1.27)',
};

const fluffShiftRight = {
  transform: 'translateX(25rem)',
  transition: 'transform .3s cubic-bezier(.68,.25,.16,1.27)',
  overflowX: 'none',
};

const Sidebar = ({
  sidebarToggle,
  setSidebarToggle,
  sidebarItems,
  focusSidebarItem,
}) => {
  // console.log(focusSidebarItem)

  const [transStyle, setTranStyle] = useState(
    setSidebarToggle ? fluffShiftRight : fluffShiftLeft
  );

  const handleItemClick = (item) => {
    if (item) {
      var elmnt = document.getElementById(item.id);
      if (elmnt) {
        elmnt.scrollIntoView();
      }
    }
  };

  const homeItems = [
    {
      name: 'Home',
      url: '/',
      id: 0,
    },

    {
      name: 'Blog',
      url: '/blog',
      id: 1,
    },
  ];

  const handleSidebarToggle = () => {
    if (sidebarToggle) {
      setSidebarToggle(false);
      setTranStyle(fluffShiftRight);
    } else {
      setSidebarToggle(true);
      setTranStyle(fluffShiftLeft);
    }
  };

  const renderSidebar = () => {
    return (
      <div className="sidebar" style={transStyle}>
        {renderItems()}
      </div>
    );
  };

  const renderItems = () => {
    const renderedItems = sidebarItems ? sidebarItems : homeItems;
    return renderedItems.map((item) => (
      <div
        className={`title ${
          focusSidebarItem.toString() === item.id.toString()
            ? 'is-highlighted'
            : ''
        }`}
        key={item.id}
        onClick={() => {
          handleItemClick(item);
        }}
      >
        <Link to={item.url}>{item.name}</Link>
      </div>
    ));
  };

  return (
    <>
      <div className="sidebar-toggle" onClick={handleSidebarToggle}>
        {sidebarToggle ? (
          <Oct.ChevronRightIcon size={24} />
        ) : (
          <Oct.ListUnorderedIcon size={24} />
        )}
      </div>
      {renderSidebar()}
    </>
  );
};

export default Sidebar;
