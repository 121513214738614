import React, { useState, useEffect, useRef } from "react";
import * as Oct from "@primer/octicons-react";
import { action } from "./util";
import useSound from "use-sound";
import ding from "./ding.mp3";
import InlineTextEdit from "./InlineTextEdit";
import * as d3 from "d3";

const initialState = () => ({
  timer: {},
  timerMode: 0,
  timerTimes: [1500000, 300000, 900000],
  taskName: "",
  muted: false,
  active: false,
  frameRate: 30,
  showSettingsModal: false,
  displayRefresh: true,
  didComplete: true,
});

const timeKeeper = (frameRate) => (fn) => setTimeout(fn, 1000 / frameRate);

const Pomororo = () => {
  const [pomodoroState, setPomodoroState] = useState(initialState());
  const timeKeeperForFrameRate = useRef(null); //holds the setTimeout to be cleared later
  const currentState = useRef({ ...pomodoroState }); //To avoid stale closure on setTimeout
  currentState.current = { ...pomodoroState };
  const svgRef = useRef(null);
  const arcGenerator = d3.arc().innerRadius(0).outerRadius(32);
  const [play] = useSound(ding);
  const {
    timerTimes,
    timerMode,
    timer,
    timer: { duration, elapsed, active, complete },
    muted,
    frameRate,
  } = pomodoroState;

  const dispatchActionForState = (state) => (type) => (val) =>
    setPomodoroState(action(type)(state)(val));
  const dispatchAction = dispatchActionForState(pomodoroState);

  //Initilize Timer based on initial state
  useEffect(() => {
    dispatchAction("RESET")();
  }, []);

  useEffect(() => {
    active
      ? (timeKeeperForFrameRate.current = timeKeeper(frameRate)(() =>
          dispatchActionForState(currentState.current)("UPDATE-TIMER")(
            Date.now()
          )
        ))
      : clearTimeout(timeKeeperForFrameRate.current);

    //Playing sound on complete
    complete && !muted && play();
    //For Timer animation
    const arcAngle = (remaining, duration) =>
      (remaining / duration) * 2 * Math.PI;
    const angle =
      arcAngle(duration - elapsed, timerTimes[timerMode]) || 2 * Math.PI;
    const arcData = [{ startAngle: 0, endAngle: -angle }];
    d3.select(svgRef.current)
      .select("g")
      .selectAll("path")
      .data(arcData)
      .join("path")
      .attr("d", arcGenerator);
  }, [timer]);

  //Pallete
  const green = "rgb(44, 92, 48)";
  const greenLight = "rgba(78, 130, 82, 0.83)";
  const blue = "rgb(40, 40, 144)";
  const blueLight = "rgba(40, 40, 158, 0.7)";

  const timerStyles = {
    0: {
      inner: { fill: "#FFA8A8" },
      moving: { fill: "rgba(234, 68, 68, 0.79)" },
    },
    1: {
      inner: { fill: green },
      moving: { fill: greenLight },
    },
    2: {
      inner: { fill: blue },
      moving: { fill: blueLight },
    },
  };

  const renderTime = (timeRemaining) => {
    const totSec = Math.floor(Math.abs(timeRemaining) / 1000);
    const mins = Math.floor(totSec / 60);
    const sec = totSec % 60;
    const addZero = (num) => (num < 10 ? "0" + num.toString() : num.toString());
    return `${addZero(mins)}:${addZero(sec)}`;
  };

  return (
    <div className="pomodoro">
      <div className="pomodoro__navbar">
        <div
          className={`pomodoro__tab pomodoro__tab--left ${
            timerMode === 0 ? "active" : active ? "disable" : ""
          }`}
          onClick={() => dispatchAction("INITIALIZE")(0)}
        >
          <h2 className="pomodoro__label pomodoro__label--small">Pomodoro</h2>
        </div>
        <div
          className={`pomodoro__tab pomodoro__tab--middle ${
            timerMode === 1 ? "active" : active ? "disable" : ""
          }`}
          onClick={() => dispatchAction("INITIALIZE")(1)}
        >
          <h2 className="pomodoro__label pomodoro__label--small">
            Short Break
          </h2>
        </div>
        <div
          className={`pomodoro__tab pomodoro__tab--right ${
            timerMode === 2 ? "active" : active ? "disable" : ""
          }`}
          onClick={() => dispatchAction("INITIALIZE")(2)}
        >
          <h2 className="pomodoro__label pomodoro__label--small">Long Break</h2>
        </div>
      </div>
      <div className="pomodoro__main">
        <div className="pomodoro__action pomodoro__action--settings">
          <Oct.SettingsIcon size={24} />
        </div>
        <div
          className="pomodoro__action pomodoro__action--audio"
          onClick={dispatchAction("MUTE-TOGGLE")}
        >
          {muted ? <Oct.MuteIcon size={24} /> : <Oct.UnmuteIcon size={24} />}
        </div>
        <div className="pomodoro__main__timer"></div>
        <div className="pomodoro__main__fixedcircle">
          {/* <Oct.DotFillIcon/> */}
          <svg
            className="pomodoro__main__fixedcircle__svg"
            style={timerStyles[timerMode]["inner"]}
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="50%" cy="50%" r="25" />
          </svg>
        </div>
        <div className="pomodoro__main__movingcircle">
          <svg
            className="pomodoro__main__movingcircle__svg"
            style={timerStyles[timerMode]["moving"]}
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            ref={svgRef}
          >
            <g transform="translate(50 50)"></g>
            {/* <circle cx="50%" cy="50%" r="32" /> */}
          </svg>
        </div>
        <div className="pomodoro__main__timelabel">
          <h2 className="pomodoro__label">{renderTime(duration - elapsed)}</h2>
        </div>
        <div
          className="pomodoro__action pomodoro__action--playpause"
          onClick={
            active
              ? () => dispatchAction("PAUSE")(Date.now())
              : () => dispatchAction("START")(Date.now())
          }
        >
          {!complete &&
            (active ? (
              <Oct.XCircleIcon size={55} />
            ) : (
              <Oct.PlayIcon size={55} />
            ))}
        </div>
      </div>

      <div className="pomodoro__status">
        <h2 className="pomodoro__label pomodoro__label--small">Working On</h2>
        {/* <h2 className="pomodoro__label pomodoro__label--large">{taskLabel}</h2> */}
        <InlineTextEdit
          taskName={pomodoroState.taskName}
          onChange={dispatchAction("TASK-CHANGE")}
        />

        <div
          className="pomodoro__action pomodoro__action--reset"
          onClick={dispatchAction("RESET")}
        >
          {false ? "" : <Oct.SyncIcon size={24} />}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Pomororo);
